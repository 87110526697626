@import "~lightgallery/dist/css/lightgallery.css";

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html, body{
	font-family: 'Ubuntu', sans-serif;
	width: 100%;
	height: 100%;
	color:#7c776c;
	font-weight: 500;
	position: relative;
}

a{
	color: #333;
	text-decoration: none;
}
.container{
	max-width: 1080px;
}

.round-btn{
	font-style: italic;
	font-weight: 500;
	background-color: transparent;
	color: #666159FF;
	font-size: 26px;
	text-align: center;
}

.rect-btn{
	font-weight: 700;
	background-color: transparent;
	color: #7D776DFF;
	font-size: 50px;
	text-align: center;
	width: 900px;
	height: 140px;
}

.img-btn{
	background-color: transparent;
	border: none;
	outline: none;
}

.btn:focus, .btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.nav-item{
	text-align: left;
    padding-left: 1rem;
    height: 4rem;
    padding-top: 0.4rem;
}
.nav-link{
	text-align: center;
	/* padding-left: 1rem; */
}


/*--- Bootstrap Padding Fix --*/
[class*="col-"] {
    padding: 1rem;
}

h1{
	font-size: 14px;
	font-weight: 700;
	color: #9e978a;
}

.lg-popis-big{
	font-size: 1.2rem;
}

.lg-popis-small{
	font-size: 1rem;
	font-weight: 400;
}

#root{
	min-height: 100vh;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 44px;
}

.loc-item{
	background-color: rgb(255, 255, 255);
    border-radius: 15px;
	width: 100%;
	max-width: 800px;
    height: 40px;
    position: absolute;
    transform: translate(-50%, -100%);
    z-index: 2;
    border: none;
    outline: none;
    cursor: pointer;
	opacity: 0;
	transition: 0.3s;
}
.loc-item:active{
	opacity: 0.5;
	outline: none;
}

@keyframes moveSlideshow {
	100% { 
	  transform: translateX(-66.6666%);  
	}
}

.Day{
	background-color: #D3CDBE;
	width: 32px;
	height: 32px;
	border-radius: 11px;
	background: none transparent;
	border: none;
	outline: none;
    cursor: pointer;
    display: inline-block;
	margin: 1px;
	font-family: 'Ubuntu', sans-serif;
	color:#7c776c;
	font-weight: 700;
	font-size: 13px;
}

.other-month{
	background-color: #fff;
	color:#b4ae9f;
	cursor: auto;
}

.normal{
	background-color: rgb(236, 235, 232);
}

.weekend{
	background-color: #BBBEEB;
}

.today{
	background-color: #7980CC;
	color: white;
}

.holidays{
	background-color: #A0DBD2;
	color:#66638f;
}

.selected{
	background-color: #69645C;
	color: white;
}

.selected-weekend, .selected-today{
	background-color: #484D91;
	color: white;
}

.selected-holidays{
	background-color: #2B8B7E;
	color:white;
}


/* Large (lg) devices (desktops, 992px and up) */
@media (max-width: 1199px) { 
	#erb-col{
		flex: 0 0 95%;
		max-width: 95%;
	}
}

/* Medium (md) devices (tablets, 768px and up) */
@media (max-width: 991px) { 
	.nav-link{
		font-size: 80%!important;
	}
	.nav-link-text{
		left: 60%!important;
	}
	#erb-col{
		flex: 0 0 100%;
		max-width: 100%;
	}
	#bg{
		margin-left: -100px;
	}
	#logo{
		left: 77%!important;
	}
	.logo-store{
		width: 150px!important;
	}
	#footer-text{
		font-size: 14px;
		padding: 0.75rem!important;
	}
}

/* Small (sm) devices (landscape phones, 576px and up) */
@media (max-width: 767px) { 
	#bg{
		margin-left: -200px;
	}
	#logo{
		left: 72%!important;
		width: 160px!important;
	}
	.logo-store{
		width: 120px!important;
	}
	.flag{
		max-height: 120px!important;
		max-width: 250px!important;
		margin-left: 3rem!important;
	}
	.download-text{
		font-size: 10px!important;
	}
	#footer-text{
		font-size: 12px;
		padding: 0.8rem 0.2rem!important;
		line-height: 1!important;
	}
	.footer-flag img{
		width: 35px!important;
	}
	.main-container{
		padding-left: 2rem!important;
		padding-right: 2rem!important;
	}
	#presov-logo-text{
		font-size: 12px!important;
	}
}

/* Extra small (xs) devices (portrait phones, less than 576px)*/
@media (max-width: 575px) { 
	.nav-link{
		font-size: 70%!important;
	}
	.nav-link-text{
		left: 65%!important;
	}
	.nav-link-icon{
		left: -10%!important;
	}
	#bg{
		margin-left: -230px;
	}
	#erb-col{
		flex: 0 0 110%;
		max-width: 110%;
	}
	#logo{
		top: 20px!important;
		left: 70%!important;
		width: 150px!important;
	}
	[class*=' col-']{
		padding: 0.5rem;
	}
	#mobil{
		margin-top: 0!important;
	}
	.main-container{
		padding-left: 1rem!important;
		padding-right: 1rem!important;
	}
	.flag{
		max-height: 100px!important;
	}
	#flag-po{
		margin-left: 7rem!important;
	}
	.footer-flag{
		margin-right: 4px!important;
	}
	.footer-flag img{
		width: 26px!important;
	}
	.footer-col{
		padding: 0.5rem 0.1rem!important;
	}
	#footer-text{
		font-size: 11px;
		padding: 0.7rem 0.1rem!important;
	}
	.loc-text{
		font-size: 14px!important;
	}
	#audio-row{
		margin-top: -25px!important;
		font-size: 12px!important;
	}
	#presov-logo-text{
		font-size: 22px!important;
	}
}

@media (max-width: 400px) {
	.nav-link-text{
		display: none;
	}
	.nav-link-icon{
		transform: translate(-50%,-50%)!important;
		left: 40%!important;
	}
	#bg{
		margin-left: -250px;
	}
	#erb-col{
		flex: 0 0 115%;
		max-width: 115%;
	}
	#logo{
		top: 30px!important;
		left: 38%!important;
		width: 140px!important;
	}
	#footer-text{
		font-size: 10px;
		padding: 0.7rem 0.1rem!important;
	}
	.loc-text{
		font-size: 12px!important;
	}
	#presov-logo-text{
		font-size: 15px!important;
	}
}

/* Extra (xl) large devices (large desktops, 1200px and up) */
/* @media (min-width: 1200px) { 

} */

